import React, { MouseEvent, useState, useCallback, useEffect, useRef, forwardRef } from 'react';
import { Box, Button, SelectChangeEvent, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@mui/material';
import { BackButton } from '../../shared/back-button';
import { CustomTypography } from '../../shared';
import routeNames from 'packages/web/src/routes/routeNames';
import ButtonPrimaryCTA from '../../shared/button-primary-cta';
import { ArrowDown } from 'packages/web/src/assets/icons/ArrowDown';
import { ArrowRight } from 'packages/web/src/assets/icons/global';
import { InfoIconTooltip } from '../../shared/info-icon-tooltip';
import DropDown from './dropDown';
import ButtonText from '../../shared/button-text';
import { ResetIcon } from 'packages/web/src/assets/icons/ResetIcon';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { getIconByAssetTypeID } from 'packages/web/src/assets/icons/assetTypes/outlined';
import { SharedConstants, SharedRedux } from '@mprofit/shared';
import { useAppDispatch, useAppSelector } from 'packages/web/src/redux/hooks';
import AddCategoryModal from './addCategoryModal';
import { ArrowUp } from 'packages/web/src/assets/icons/ArrowUp';
import NavigationTabs from '../../shared/navigation-tabs';
import SharedDropDown from '../../shared/drop-down';
import { PreferenceSettingIcon } from 'packages/web/src/assets/icons/PreferenceSettingIcon';
import { TableSearchBar } from '../../shared/table-search-bar';
import SkeletonLoader from '../../shared/skeleton-loader';
import { TableVirtuoso } from 'react-virtuoso';
import { useOverlayScrollbars } from 'overlayscrollbars-react';
import 'overlayscrollbars/overlayscrollbars.css';
import ConfirmModal from '../../shared/confirm-modal';
import { NavigateToPortfolio } from 'packages/web/src/routes/navigationMethods';
import { NavigateToAssetCategorization } from 'packages/web/src/routes/navigationMethods';

interface CategoryMapping {
  product: string;
  category: string;
  subCategory: string;
}

interface MappingData {
  categoryId?: number;
  categoryName?: string;
  subCategoryId?: number;
  subCategoryName?: string;
  isDefault?: boolean;
}

interface AssetAllocationMapping {
  AssetTypeID: number;
  AssetAllocationCategID: number;
  AMID: number;
  CustomAssetAllocationCategID: number;
  CustomAssetAllocationSubCategID: number;
}

interface SubCategoryOption {
  id: number;
  name: string;
  isDefault: boolean;
}

interface ModalConfig {
  type: 'category' | 'subcategory';
  parentCategoryId?: number;
  key?: string;
}

interface AssetCategoryItemProps {
  icon: React.ReactNode;
  title: string;
  onClick: () => void;
  children: (searchTerm: string, setSearchTerm: React.Dispatch<React.SetStateAction<string>>) => React.ReactNode;
  mappings?: CategoryMapping[];
  isExpanded: boolean;
  onToggle: (expanded: boolean) => void;
}

export const AssetCategoryItem: React.FC<AssetCategoryItemProps> = ({ icon, title, onClick, children, isExpanded, onToggle }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const handleToggle = () => {
    onToggle(!isExpanded);
    if (onClick) onClick();
  };

  return (
    <Box display="flex" flexDirection="column" sx={{ background: "#FFFFFF", borderBottom: "1px solid #EBECF2", marginBottom: "8px", position: "relative", overflow: isExpanded ? "visible" : "hidden" }}>
      <Box display="flex" justifyContent="space-between" alignItems="center" sx={{ padding: "24px", cursor: 'pointer' }} onClick={handleToggle}>
        <Box display="flex" flexDirection="row" gap="24px" alignItems="center">
          <Box sx={{ width: "48px", height: "48px", display: "flex", alignItems: "center", justifyContent: "center", ".icon-by-asset-type-fill": { fill: '#12131A' }, ".icon-by-asset-type-stroke": { stroke: '#12131A' } }}>
            {icon}
          </Box>
          <CustomTypography variant='Regular24' color='#12131A' sx={{ lineHeight: "32px" }}>
            {title}
          </CustomTypography>
        </Box>
        <Box display="flex" alignItems="center">
          {isExpanded ? <ArrowUp /> : <ArrowDown />}
        </Box>
      </Box>
      {isExpanded && (
        <Box sx={{ position: "relative", zIndex: 1 }}>
          {children(searchTerm, setSearchTerm)}
        </Box>
      )}
    </Box>
  );
};

export const AssetCategorization = () => {
  const dispatch = useAppDispatch();
  const activeScreenIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActiveScreenIDs);
  const assetCategorizationData = useAppSelector(SharedRedux.Dashboard.Selectors.selectAssetCategorizationData);
  const categoryOptions = useAppSelector(SharedRedux.Dashboard.Selectors.selectCategoryOptions);
  const categoriesById = useAppSelector(SharedRedux.Dashboard.Selectors.selectCustomAssetAllocationCategoriesById);

  const [openCategoryKey, setOpenCategoryKey] = useState<string | null>(null);
  const [openSubCategoryKey, setOpenSubCategoryKey] = useState<string | null>(null);
  const [selectedMappings, setSelectedMappings] = useState<Record<string, MappingData>>({});
  const [loadingAssets, setLoadingAssets] = useState<Record<number, boolean>>({});
  const [scroller, setScroller] = useState<HTMLElement | null>(null);
  const rootRef = useRef<HTMLDivElement>(null);
  const [initialize, osInstance] = useOverlayScrollbars({
    options: { scrollbars: { autoHide: "scroll" } },
    defer: true,
  });

  useEffect(() => {
    const { current: root } = rootRef;

    if (scroller && root) {
      initialize({
        target: root,
        elements: {
          viewport: scroller,
        },
      });
    }

    return () => osInstance()?.destroy();
  }, [scroller, initialize, osInstance]);

  const TABS = {
    PRODUCTS: 1,
    ASSETS: 2,
  } as const;

  type TabType = typeof TABS[keyof typeof TABS];

  const CATEGORY_TYPES = {
    ALL: 'AllCategory',
    DEFAULT: 'DefaultCategory',
    CUSTOM: 'CustomCategory'
  } as const;

  type DropdownCategoryType = typeof CATEGORY_TYPES[keyof typeof CATEGORY_TYPES];

  const dropdownList = [{id: CATEGORY_TYPES.ALL, name: 'All'}, {id: CATEGORY_TYPES.DEFAULT, name: 'Default'}, {id: CATEGORY_TYPES.CUSTOM, name: 'Custom'}]; 

  const tabs = [TABS.PRODUCTS, TABS.ASSETS];
  const [activeTab, setActiveTab] = useState<TabType>(TABS.PRODUCTS);
  const [dropdownValue, setDropdownValue] = useState<DropdownCategoryType>(CATEGORY_TYPES.ALL);

  const onChangeDropdownValue = (newDropdownValue: DropdownCategoryType) => {
    setDropdownValue(newDropdownValue);
    if (activeTab === TABS.PRODUCTS) {
      if (newDropdownValue === CATEGORY_TYPES.ALL) {
        setExpandedItems({});
      } else if (assetCategorizationData) {
        var newExpandedItems: {[AssetTypeID: number]: boolean} = {};
        assetCategorizationData.forEach((assetGroup, index) => {
          newExpandedItems[assetGroup.AssetTypeID] = true;
        });
        setExpandedItems(newExpandedItems);
      }
    }
  }
  
  const assetCategorizationViewData = useAppSelector(SharedRedux.Dashboard.Selectors.selectAssetCategorizationViewData);
  
  const handleTabChange = (event: MouseEvent<HTMLElement>, newValue: any) => {
    if (newValue && newValue !== activeTab) {
      setExpandedItems({});
      setActiveTab(newValue);
    }
  };
  
  const [resetToDefaultAPIResponse, setResetToDefaultAPIResponse] = useState<SharedConstants.FormSubmissionResponse | undefined>(undefined);

  const getSubCategoryOptions = useCallback((categoryId: number): SubCategoryOption[] => {
    const category = categoriesById[categoryId];
    if (!category) return [];

    return Object.entries(category.SubCategs).map(([id, subCategory]) => ({
      id: Number(id),
      name: subCategory.SubCategName,
      isDefault: subCategory.IsDefault
    })).sort((a, b) => a.name.localeCompare(b.name));
  }, [categoriesById]);

  const navigate = useNavigate();
  const location = useLocation();
  const [isEdit, setIsEdit] = useState(false);
  const [isAddCategoryModalOpen, setIsAddCategoryModalOpen] = useState(false);
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);
  const activePortfolioIDs = useAppSelector(SharedRedux.Portfolios.Selectors.selectActivePortfolioIDs);
  const [isResetToDefaultModalOpen, setIsResetToDefaultModalOpen] = useState<boolean>(false);
  
  const handleMappingUpdate = useCallback((key: string, updates: Partial<MappingData>) => {
    // Products tab keys (format: "product_assetGroupId_productId")
    // Assets tab keys (format: "asset_assetGroupId_assetId")
    let mappingKey = key;
    setSelectedMappings(prev => ({
      ...prev,
      [mappingKey]: {
        ...prev[mappingKey],
        ...updates
      }
    }));
  }, []);

  const generateMappingKey = useCallback((tabType: 'product' | 'asset', assetGroupId: number, itemId: number): string => {
    return tabType === 'product' 
      ? `product_${assetGroupId}_${itemId}` 
      : `asset_${assetGroupId}_${itemId}`;
  }, []);

  const handleSelectChange = (
    event: SelectChangeEvent<string>,
    assetGroupId: number,
    itemId: number,
    isSubCategory: boolean = false,
    tabType: 'product' | 'asset' = 'product'
  ) => {
    // const productIndex = tabType === 'product' ? itemId : -1;
    // const assetIndex = tabType === 'asset' ? itemId : -1;
    
    const mappingKey = generateMappingKey(tabType, assetGroupId, itemId);

    if (isSubCategory) {
      let categoryId: number | undefined;
      
      if (tabType === 'product') {
        // For Products tab
        const subCategory = assetCategorizationData.find(group => group.AssetTypeID === assetGroupId)?.SubCategories.find(sub => sub.AssetAllocationCategID === itemId);
        if (!subCategory) {
          console.log('Product subcategory not found');
          return;
        }
        
        categoryId = selectedMappings[mappingKey]?.categoryId ||
          subCategory.mapping?.CustomAssetAllocationCategID;
      } else {
        // For Assets tab
        const assetViewGroup = assetCategorizationViewData.find(
          (viewGroup) => viewGroup.AssetTypeID === assetGroupId
        );
        
        if (!assetViewGroup || !assetViewGroup.Assets) {
          console.log('Asset view group or assets not found');
          return;
        }
        
        const asset = assetViewGroup.Assets.find(asset => asset.AMID === itemId);

        if (!asset) {
          console.log('Asset not found');
          return;
        }
        
        categoryId = selectedMappings[mappingKey]?.categoryId || asset.CustomAssetAllocationCategID;
      }

      if (!categoryId) {
        console.log('No category selected. Please select a category first.');
        return;
      }

      const category = categoriesById[categoryId];
      if (!category) {
        console.log('Category not found:', categoryId);
        return;
      }

      const subCategoryId = Number(event.target.value);
      const subCategory = category.SubCategs[subCategoryId];
      
      if (!subCategory) {
        console.log('Subcategory not found:', subCategoryId);
        return;
      }

      setSelectedMappings(prev => ({
        ...prev,
        [mappingKey]: {
          ...prev[mappingKey],
          categoryId,
          categoryName: category.CategName,
          subCategoryId,
          subCategoryName: subCategory.SubCategName
        }
      }));
    } else {
      const categoryId = Number(event.target.value);
      const category = categoriesById[categoryId];

      // const firstSubCategEntry = Object.entries(category?.SubCategs || {})[0];
      // const [firstSubCategId, firstSubCategory] = firstSubCategEntry || [null, null];
      
      if (!category) {
        console.log('Category not found:', categoryId);
        return;
      }

      setSelectedMappings(prev => ({
        ...prev,
        [mappingKey]: {
          categoryId: categoryId,
          categoryName: category.CategName || '',
          subCategoryId: undefined,
          subCategoryName: ''
        }
      }));
      setOpenCategoryKey(null);
      return;
    }

    if (isSubCategory) {
      setOpenSubCategoryKey(null);
    } else {
      setOpenCategoryKey(null);
    }
  };

  const handleAddCategory = (name: string) => {
    if (!name || !modalConfig) {
      console.log('Missing name or modal configuration for adding category');
      return;
    }

    if (!activeScreenIDs?.DatabaseID) {
      console.log('No active database ID found');
      return;
    }

    const trimmedName = name.trim();
    if (trimmedName.length === 0) {
      console.log('Category name cannot be empty');
      return;
    }

    if (modalConfig.type === 'category') {
      dispatch(SharedRedux.Dashboard.Actions.insertCustomAssetAllocationCategory({
        customAssetAllocationCategName: trimmedName,
        callback: (newCategoryId: number) => {
          if (!newCategoryId || newCategoryId <= 0) {
            console.log('Failed to create category: Invalid ID returned');
            return;
          }

          if (modalConfig.key) {
            try {
              // Remove '_category' suffix if present to get the base key
              const baseKey = modalConfig.key.replace('_category', '');
              
              // Validate key format
              if (!baseKey.startsWith('product_') && !baseKey.startsWith('asset_')) {
                console.log('Invalid key format:', baseKey);
                return;
              }
              
              handleMappingUpdate(baseKey, {
                categoryId: newCategoryId,
                categoryName: trimmedName,
                subCategoryId: undefined,
                subCategoryName: ''
              });
              setOpenCategoryKey(null);
            } catch (error) {
              console.log('Error updating mapping with new category:', error);
            }
          }
        }
      }));
    } else {
      if (!modalConfig.parentCategoryId || modalConfig.parentCategoryId <= 0) {
        console.log('Invalid parent category ID for subcategory creation');
        return;
      }

      dispatch(SharedRedux.Dashboard.Actions.insertCustomAssetAllocationSubCategory({
        customAssetAllocationCategID: modalConfig.parentCategoryId,
        customAssetAllocationSubCategName: trimmedName,
        callback: (newSubCategoryId: number) => {
          if (!newSubCategoryId || newSubCategoryId <= 0) {
            console.log('Failed to create subcategory: Invalid ID returned');
            return;
          }

          if (modalConfig.key) {
            const baseKey = modalConfig.key.replace('_subcategory', '');
              
            // Validate key format
            if (!baseKey.startsWith('product_') && !baseKey.startsWith('asset_')) {
              console.log('Invalid key format:', baseKey);
              return;
            }

            handleMappingUpdate(baseKey, {
              subCategoryId: newSubCategoryId,
              subCategoryName: trimmedName,
              categoryId: modalConfig.parentCategoryId
            });
            setOpenSubCategoryKey(null);
          }
        }
      }));
    }
    
    setIsAddCategoryModalOpen(false);
    setModalConfig(null);
  };

  const handleAddNewItem = (categoryId: number | null, type: 'category' | 'subcategory', key: string) => {
    setModalConfig({
      type,
      parentCategoryId: categoryId || undefined,
      key
    });
    setIsAddCategoryModalOpen(true);
  };

  const handleSave = useCallback(() => {
    const hasInvalidMapping = Object.values(selectedMappings).some(mapping => 
      !mapping.subCategoryId || !mapping.categoryId
    );

    if (hasInvalidMapping) {
      console.warn('Some mappings are invalid. Please complete all mappings.');
      return;
    }

    const mappingsToUpdate = Object.entries(selectedMappings)
      .map(([key, mapping]) => {
        try {
          if (key.startsWith('asset_')) {
            const [_, assetGroupId, itemId] = key.split('_').map(Number);
            
            // Validate indices
            if (isNaN(assetGroupId) || isNaN(itemId)) {
              console.log('Invalid asset mapping key format:', key);
              return null;
            }
            
            const assetViewGroup = assetCategorizationViewData.find(viewGroup => 
              viewGroup.AssetTypeID === assetGroupId
            );
            
            if (!assetViewGroup || !assetViewGroup.Assets) {
              console.log('Asset view group or assets not found for AssetTypeID:', assetGroupId);
              return null;
            }
            
            const asset = assetViewGroup.Assets.find(asset => asset.AMID === itemId);
            if (!asset) {
              console.log('Asset not found at index:', itemId);
              return null;
            }

            return {
              AssetTypeID: assetGroupId,
              AssetAllocationCategID: -1,
              AMID: itemId,
              CustomAssetAllocationCategID: mapping.categoryId || asset.CustomAssetAllocationCategID || -1,
              CustomAssetAllocationSubCategID: mapping.subCategoryId || asset.CustomAssetAllocationSubCategID || -1
            };
          } else if (key.startsWith('product_')) {
            const [_, assetGroupId, itemId] = key.split('_').map(Number);
            
            // Validate indices
            if (isNaN(assetGroupId) || isNaN(itemId)) {
              console.log('Invalid product mapping key format:', key);
              return null;
            }
            
            const assetGroup = assetCategorizationData.find(group => group.AssetTypeID === assetGroupId);
            if (!assetGroup || !assetGroup.SubCategories) {
              console.log('Asset group or subcategories not found for index:', assetGroupId);
              return null;
            }
            
            const subCategory = assetGroup.SubCategories?.find(sub => sub.AssetAllocationCategID === itemId);
            if (!subCategory) {
              console.log('Product subcategory not found at index:', itemId);
              return null;
            }

            return {
              AssetTypeID: assetGroupId,
              AssetAllocationCategID: subCategory.AssetAllocationCategID,
              AMID: -1,
              CustomAssetAllocationCategID: mapping.categoryId || subCategory.mapping?.CustomAssetAllocationCategID || -1,
              CustomAssetAllocationSubCategID: mapping.subCategoryId || subCategory.mapping?.CustomAssetAllocationSubCategID || -1
            };
          }
        } catch (error) {
          console.log('Error processing mapping:', key, error);
        }
        return null;
      })
      .filter(Boolean) as AssetAllocationMapping[];

    if (mappingsToUpdate.length > 0 && activeScreenIDs?.DatabaseID) {
      dispatch(SharedRedux.Dashboard.Actions.updateCustomAssetAllocationMappings({
        mappings: {
          List: mappingsToUpdate
        }
      }));
    }
    
    setIsEdit(false);
    setSelectedMappings({});
  }, [selectedMappings, assetCategorizationData, assetCategorizationViewData, activeScreenIDs, dispatch]);

  const onResetAllToDefaultClick = () => {
    setIsResetToDefaultModalOpen(true);
  }

  // const handleReset = useCallback(() => {
  //   if (activeScreenIDs?.DatabaseID) {
  //     dispatch(SharedRedux.Dashboard.Actions.resetCustomAssetAllocationToDefault({
  //       includeAssets: false
  //     }));
  //     setSelectedMappings({});
  //     setIsEdit(false);
  //   }
  // }, [activeScreenIDs, dispatch]);

  const handleCancelClick = () => {
    setIsEdit(false);
    setSelectedMappings({});
    setOpenCategoryKey(null);
    setOpenSubCategoryKey(null);
  };

  const [expandedItems, setExpandedItems] = useState<Record<number, boolean>>({});

  const handleToggleExpand = (assetGroupId: number, expanded: boolean) => {
    setExpandedItems(prev => ({
      ...prev,
      [assetGroupId]: expanded
    }));
  };

  const handleAssetCategoryExpand = useCallback((assetTypeId: number) => {
    if (activeTab === TABS.ASSETS) {
      setLoadingAssets(prev => ({
        ...prev,
        [assetTypeId]: true
      }));

      dispatch(SharedRedux.Dashboard.Actions.fetchCustomAssetAllocationAssets({
        assetTypeId
      }));
    }
  }, [dispatch, activeTab]);

  useEffect(() => {
    if (activeTab === TABS.ASSETS && assetCategorizationViewData.length > 0) {
      Object.keys(loadingAssets).forEach(assetTypeIdStr => {
        const assetTypeId = Number(assetTypeIdStr);
        if (loadingAssets[assetTypeId]) {
          const assetGroup = assetCategorizationViewData.find(
            (viewGroup) => viewGroup.AssetTypeID === assetTypeId
          );

          if (assetGroup && assetGroup.Assets !== undefined) {
            setLoadingAssets(prev => ({
              ...prev,
              [assetTypeId]: false
            }));
          }
        }
      });
    }
  }, [assetCategorizationViewData, loadingAssets, activeTab]);

  const filterByMappingType = (mappingType: string | undefined) => {
    if (dropdownValue === CATEGORY_TYPES.ALL) return true;
    if (!mappingType) return dropdownValue === CATEGORY_TYPES.DEFAULT;
    if (dropdownValue === CATEGORY_TYPES.DEFAULT) return mappingType === 'default';
    if (dropdownValue === CATEGORY_TYPES.CUSTOM) return mappingType === 'custom';
    return true;
  };

  const prepareVirtualizedData = useCallback((assetGroup: any, assetGroupId: number, searchTerm: string = '') => {
    if (activeTab === TABS.PRODUCTS) {
      return assetGroup.SubCategories
        .filter((subCategory: any) => filterByMappingType(subCategory.mapping?.mappingType))
        .map((subCategory: any) => ({
          type: 'product',
          assetGroupId: assetGroupId,
          productId: subCategory.AssetAllocationCategID,
          data: subCategory
        }));
    } else {
      if (loadingAssets[assetGroup.AssetTypeID]) {
        return Array.from({ length: 5 }).map((_, index) => ({
          type: 'skeleton',
          index
        }));
      }

      const assetViewGroup = assetCategorizationViewData.find(
        (viewGroup) => viewGroup.AssetTypeID === assetGroup.AssetTypeID
      );
      
      if (!assetViewGroup || !assetViewGroup.Assets) {
        return [];
      }

      return assetViewGroup.Assets
        .filter((asset) => asset.AssetName.toLowerCase().includes(searchTerm.toLowerCase()))
        .filter((asset) => filterByMappingType((asset as any).mappingType))
        .map((asset) => ({
          type: 'asset',
          assetGroupId: assetGroup.AssetTypeID,
          assetId: asset.AMID,
          data: asset
        }));
    }
  }, [activeTab, loadingAssets, assetCategorizationViewData, filterByMappingType]);

  const renderVirtualizedTable = useCallback((assetGroup: any, assetGroupId: number, searchTerm: string = '') => {
    const rows = prepareVirtualizedData(assetGroup, assetGroupId, searchTerm);
    
    const COLUMN_WIDTHS = {
      item: '30%',
      arrow: '5%',
      category: '30%',
      arrow2: '5%',
      subcategory: '30%'
    };
    
    const renderSkeletonRow = (row: any) => {
      return (
        <>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.item }}>
            <Box>
              <SkeletonLoader animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#e2e2e2', height: '30px', width: '200px' }} sxContainer={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} />
            </Box>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.arrow }}>
            <ArrowRight />
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.category }}>
            <Box>
              <SkeletonLoader animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#e2e2e2', height: '30px', width: '120px' }} sxContainer={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} />
            </Box>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.arrow2 }}>
            <ArrowRight />
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.subcategory }}>
            <Box>
              <SkeletonLoader animation="wave" variant="rounded" style={{ borderRadius: '100px', backgroundColor: '#e2e2e2', height: '30px', width: '100px' }} sxContainer={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }} />
            </Box>
          </TableCell>
        </>
      );
    };

    const renderProductRow = (row: any) => {
      const { assetGroupId, productId, data: subCategory } = row;
      const dropdownKey = generateMappingKey('product', assetGroupId, productId);
      const categoryKey = `${dropdownKey}_category`;
      const subCategoryKey = `${dropdownKey}_subcategory`;

      const selectedMapping = selectedMappings[dropdownKey];
      const currentMapping = subCategory.mapping;

      const displayCategoryName = selectedMapping?.categoryName || currentMapping?.CategName || '';
      const displaySubCategoryName = selectedMapping?.categoryId 
        ? selectedMapping?.subCategoryName 
        : currentMapping?.SubCategName || '';
      const isDefaultCategory = selectedMapping?.isDefault ?? currentMapping?.isDefault ?? false;

      return (
        <>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.item }}>
            <Box>
              <CustomTypography
                variant='Regular18'
                color='#FFFFFF'
                sx={{
                  lineHeight: "27px",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  background: "#3A3D4D",
                  width: "fit-content",
                }}
              >
                {subCategory.DefaultSubCategName}
              </CustomTypography>
            </Box>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.arrow }}>
            <ArrowRight />
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.category }}>
            {!isEdit ? (
              <CustomTypography
                variant='Regular20'
                color='#3A3D4D'
                sx={{ lineHeight: "30px", width: "fit-content" }}>
                {displayCategoryName}
              </CustomTypography>
            ) : (
              <DropDown
                open={openCategoryKey === categoryKey}
                setOpen={() => setOpenCategoryKey(openCategoryKey === categoryKey ? null : categoryKey)}
                selectedValue={displayCategoryName}
                handleSelectChange={(e) => handleSelectChange(e, assetGroupId, productId, false, 'product')}
                dropdownList={categoryOptions}
                disabled={isDefaultCategory}
                handleNewSubCategory={() => handleAddNewItem(null, 'category', categoryKey)}
                headerText="Category"
                minWidth={{ md: "150px", lg: "250px", xl: "330px" }}
                selectedCategory={subCategory}
                type="category"
                selectedCategId={selectedMapping?.categoryId || currentMapping?.CustomAssetAllocationCategID}
              />
            )}
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.arrow2 }}>
            <ArrowRight />
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.subcategory }}>
            {!isEdit ? (
              <CustomTypography variant='Regular20'
                color='#3A3D4D'
                sx={{ lineHeight: "30px", width: "fit-content" }}>
                {displaySubCategoryName}
              </CustomTypography>
            ) : (
              <DropDown
                open={openSubCategoryKey === subCategoryKey}
                setOpen={() => setOpenSubCategoryKey(openSubCategoryKey === subCategoryKey ? null : subCategoryKey)}
                selectedValue={displaySubCategoryName || ''}
                handleSelectChange={(e) => handleSelectChange(e, assetGroupId, productId, true, 'product')}
                dropdownList={getSubCategoryOptions(selectedMapping?.categoryId || currentMapping?.CustomAssetAllocationCategID || 0)}
                disabled={isDefaultCategory || (!selectedMapping?.categoryId && !currentMapping?.CustomAssetAllocationCategID)}
                handleNewSubCategory={() => handleAddNewItem(selectedMapping?.categoryId || currentMapping?.CustomAssetAllocationCategID || 0, 'subcategory', subCategoryKey)}
                headerText="Sub-Category"
                minWidth={{ md: "150px", lg: "250px", xl: "330px" }}
                selectedCategory={subCategory}
                type="subcategory"
                selectedCategId={selectedMapping?.categoryId || currentMapping?.CustomAssetAllocationCategID}
                isRequired={true}
              />
            )}
          </TableCell>
        </>
      );
    };

    const renderAssetRow = (row: any) => {
      const { assetGroupId, assetId, data: asset } = row;
      const dropdownKey = `asset_${assetGroupId}_${assetId}`;
      const categoryKey = `${dropdownKey}_category`;
      const subCategoryKey = `${dropdownKey}_subcategory`;

      const selectedMapping = selectedMappings[dropdownKey];
      
      const displayCategoryName = selectedMapping?.categoryName || asset.CategName || '';
      const displaySubCategoryName = selectedMapping?.categoryId 
        ? selectedMapping?.subCategoryName 
        : asset.SubCategName || '';
      const isDefaultCategory = selectedMapping?.isDefault ?? false;

      const categoryId = selectedMapping?.categoryId || asset.CustomAssetAllocationCategID;
      const subCategoryOptions = categoryId ? getSubCategoryOptions(categoryId) : [];

      return (
        <>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.item }}>
            <Box>
              <CustomTypography
                variant='Regular18'
                color='#FFFFFF'
                sx={{
                  lineHeight: "27px",
                  borderRadius: "4px",
                  padding: "4px 8px",
                  background: "#3A3D4D",
                  width: "fit-content",
                }}
              >
                {asset.AssetName}
              </CustomTypography>
            </Box>
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.arrow }}>
            <ArrowRight />
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.category }}>
            {!isEdit ? (
              <CustomTypography
                variant='Regular20'
                color='#3A3D4D'
                sx={{ lineHeight: "30px", width: "fit-content" }}>
                {displayCategoryName}
              </CustomTypography>
            ) : (
              <DropDown
                open={openCategoryKey === categoryKey}
                setOpen={() => setOpenCategoryKey(openCategoryKey === categoryKey ? null : categoryKey)}
                selectedValue={displayCategoryName}
                handleSelectChange={(e) => handleSelectChange(e, assetGroupId, assetId, false, 'asset')}
                dropdownList={categoryOptions}
                disabled={isDefaultCategory}
                handleNewSubCategory={() => handleAddNewItem(null, 'category', categoryKey)}
                headerText="Category"
                minWidth={{ md: "150px", lg: "250px", xl: "330px" }}
                selectedCategory={{
                  AssetTypeID: assetGroupId,
                  AssetAllocationCategID: -1,
                  DefaultSubCategName: asset.text,
                  DefaultCategID: asset.DefaultCategID,
                  DefaultSubCategID: asset.DefaultSubCategID
                } as any}
                type="category"
                selectedCategId={categoryId}
              />
            )}
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.arrow2 }}>
            <ArrowRight />
          </TableCell>
          <TableCell sx={{ borderBottom: "none", width: COLUMN_WIDTHS.subcategory }}>
            {!isEdit ? (
              <CustomTypography variant='Regular20'
                color='#3A3D4D'
                sx={{ lineHeight: "30px", width: "fit-content" }}>
                {displaySubCategoryName}
              </CustomTypography>
            ) : (
              <DropDown
                open={openSubCategoryKey === subCategoryKey}
                setOpen={() => setOpenSubCategoryKey(openSubCategoryKey === subCategoryKey ? null : subCategoryKey)}
                selectedValue={displaySubCategoryName || ''}
                handleSelectChange={(e) => handleSelectChange(e, assetGroupId, assetId, true, 'asset')}
                dropdownList={subCategoryOptions}
                disabled={isDefaultCategory || !categoryId}
                handleNewSubCategory={() => handleAddNewItem(categoryId || 0, 'subcategory', subCategoryKey)}
                headerText="Sub-Category"
                minWidth={{ md: "150px", lg: "250px", xl: "330px" }}
                selectedCategory={{
                  AssetTypeID: assetGroupId,
                  AssetAllocationCategID: -1,
                  DefaultSubCategName: asset.text,
                  DefaultCategID: asset.DefaultCategID,
                  DefaultSubCategID: asset.DefaultSubCategID
                } as any}
                type="subcategory"
                selectedCategId={categoryId}
              />
            )}
          </TableCell>
        </>
      );
    };

    const rowRenderMap: Record<string, (row: any) => React.ReactNode> = {
      'skeleton': renderSkeletonRow,
      'product': renderProductRow,
      'asset': renderAssetRow,
    };
    
    return (
      <Box
        data-overlayscrollbars-initialize=""
        ref={rootRef}
        sx={{ display: "block", position: "relative", overflow: "hidden" }}
      >
        <TableVirtuoso
          scrollerRef={(ref) => {
            if (ref instanceof HTMLElement) {
              setScroller(ref);
            }
          }}
          style={{
            background: "#F9F9F9",
            borderRadius: "8px",
            width: "100%",
            height: 57 + 80 * rows.length,
            maxHeight: "400px",
          }}
          data={rows}
          components={{
            Table: (props) => (
              <Table
                {...props}
                style={{
                  borderCollapse: "separate",
                  tableLayout: "fixed",
                  width: "100%"
                }}
              />
            ),
            TableHead,
            TableBody: forwardRef((props, ref) => (
              <TableBody {...props} ref={ref} />
            )),
            TableRow,
            TableFoot: TableFooter,
          }}
          fixedHeaderContent={() => (
            <TableRow sx={{ background: "#F9F9F9" }}>
              <TableCell sx={{ borderBottom: "1px solid #D8DAE5", width: COLUMN_WIDTHS.item }}>
                <Box display="flex" flexDirection="row" alignItems="center" gap="12px">
                  <CustomTypography variant='Medium16' color='#64677A' sx={{ lineHeight: "24px", textTransform: "uppercase" }}>{activeTab === TABS.PRODUCTS ? "Product" : "Asset"}</CustomTypography>
                </Box>
              </TableCell>
              <TableCell sx={{ borderBottom: "1px solid #D8DAE5", width: COLUMN_WIDTHS.arrow }}></TableCell>
              <TableCell sx={{ borderBottom: "1px solid #D8DAE5", width: COLUMN_WIDTHS.category }}>
                <Box display="flex" flexDirection="row" alignItems="center" gap="12px">
                  <CustomTypography variant='Medium16' color='#64677A' sx={{ lineHeight: "24px", textTransform: "uppercase" }}>Category</CustomTypography>
                  <InfoIconTooltip tooltip="Assign categories to your investments, as per your asset allocation preferences" placement='right' color='#BABDCC' />
                </Box>
              </TableCell>
              <TableCell sx={{ borderBottom: "1px solid #D8DAE5", width: COLUMN_WIDTHS.arrow2 }}></TableCell>
              <TableCell sx={{ borderBottom: "1px solid #D8DAE5", width: COLUMN_WIDTHS.subcategory }}>
                <Box display="flex" flexDirection="row" alignItems="center" gap="12px">
                  <CustomTypography variant='Medium16' color='#64677A' sx={{ lineHeight: "24px", textTransform: "uppercase" }}>Sub-Category</CustomTypography>
                  <InfoIconTooltip tooltip="Assign sub-categories within each category, as per your asset allocation preferences" placement='right' color='#BABDCC' />
                </Box>
              </TableCell>
            </TableRow>
          )}
          itemContent={(index, row: any) => {
            const renderFunction = rowRenderMap[row.type];
            return renderFunction ? renderFunction(row) : null;
          }}
        />
      </Box>
    );
  }, [
    activeTab, 
    isEdit, 
    categoryOptions, 
    openCategoryKey, 
    openSubCategoryKey, 
    selectedMappings, 
    getSubCategoryOptions, 
    handleSelectChange, 
    handleAddNewItem, 
    generateMappingKey,
    prepareVirtualizedData
  ]);
  
  const onCancelResetToDefaultClick = () => {
    setIsResetToDefaultModalOpen(false);
    setResetToDefaultAPIResponse(undefined);
  }

  const handleNavigationBackToAssetCategorization = () => {
    NavigateToAssetCategorization({ navigate, activeScreenIDs, pathname: routeNames.MANAGE_CATEGORIES, activeScreen: SharedConstants.ScreenEnum.ManageAssetCategorization })
  }

  return (
    <>
      <Box sx={{ margin: "0px 35px", minHeight: "calc(100% - 88px)" }}>
        <BackButton title='Back to Dashboard' 
          handleNavigate={() => NavigateToPortfolio({ navigate, activePortfolio: activePortfolioIDs })}
        />
        <Box sx={{ marginTop: "32px" }}>
          <Box sx={{display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between"}}>
            <CustomTypography variant='Medium28' color='#12131A' sx={{ lineHeight: "33.18px" }}>
              Custom Categories
            </CustomTypography>

            <Box sx={{ display: "flex", flexDirection: "row", alignItems: "center", gap: "16px" }}>
              <SharedDropDown
                startIcon={<PreferenceSettingIcon />}
                inputValue={dropdownValue}
                inputList={dropdownList}
                handleChange={(selected) => { onChangeDropdownValue(selected.id as DropdownCategoryType) }}
              />
              <NavigationTabs
                tabIDs={tabs}
                tabTexts={{
                  [TABS.PRODUCTS]: "By Asset Class",
                  [TABS.ASSETS]: "By Asset",
                }}
                disabledTabs={{
                  [TABS.PRODUCTS]: isEdit && activeTab === TABS.ASSETS,
                  [TABS.ASSETS]: isEdit && activeTab === TABS.PRODUCTS,
                }}
                handleChange={handleTabChange}
                activeID={activeTab}
                sxProps={{
                  // width: "244px",
                  display: "grid",
                  gridTemplateColumns: `repeat(${tabs.length}, 1fr)`,
                  borderRadius: "4px",
                }}
              />
            </Box>
          </Box>
        </Box>
        <Box sx={{ marginTop: "22px" }}>
          {assetCategorizationData.map((assetGroup, idx) => {
            const IconByAssetTypeID = getIconByAssetTypeID(assetGroup.AssetTypeID);
            return (
              <AssetCategoryItem
                key={idx}
                icon={IconByAssetTypeID && <IconByAssetTypeID />}
                title={assetGroup.AssetTypeName}
                onClick={() => handleAssetCategoryExpand(assetGroup.AssetTypeID)}
                isExpanded={!!expandedItems[assetGroup.AssetTypeID]}
                onToggle={(expanded) => handleToggleExpand(assetGroup.AssetTypeID, expanded)}
              >
                {(searchTerm, setSearchTerm) => (
                  <Box sx={{ borderTop: "1px solid #EBECF2" }}>
                    <Box sx={{ margin: "24px 40px 32px 56px" }}>
                      {activeTab === TABS.ASSETS && (
                        <Box width="407px" height="47px" sx={{marginBottom: "24px"}}>
                          <TableSearchBar 
                            searchTerm={searchTerm} 
                            setSearchTerm={setSearchTerm} 
                          />
                        </Box>
                      )}
                      {renderVirtualizedTable(assetGroup, assetGroup.AssetTypeID, searchTerm)}
                    </Box>
                  </Box>
                )}
              </AssetCategoryItem>
            )
          })}
        </Box>
      </Box>
      <Box sx={{
        // position: "fixed",
        position: "sticky",
        bottom: "0px",
        // right: "0px",
        width: "100%",
        backgroundColor: "#FFFFFF",
        borderTop: "1px solid #D8DAE5",
        padding: "32px 48px 32px 40px",
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        boxSizing: "border-box",
        zIndex: 10,
        // width: "calc(100% - 40px)"
      }}>
        <Box sx={{}}>
          {isEdit &&
            <Button
              startIcon={<ResetIcon />}
              onClick={onResetAllToDefaultClick}
              sx={{
                backgroundColor: "#FFFFFF",
                color: "#64677A",
                border: "1px solid #D8DAE5",
                padding: "7px 16px",
                fontSize: "16px",
                fontWeight: "500",
                textAlign: "center",
                textTransform: "none"
              }}>
              Reset all to default
            </Button>
          }
        </Box>
        <Box sx={{ display: "flex", flexDirection: "row", gap: "16px" }}>
          {isEdit ?
            <ButtonText
              sxProps={{
                minWidth: "140px",
                height: "48px"
              }}
              buttonText="Cancel"
              handleClick={handleCancelClick}
            />
            :
            <Button sx={{ backgroundColor: "#FFFFFF", color: "#64677A", border: "1px solid #D8DAE5", padding: "0 16px", fontSize: "16px", lineHeight: "18.96px", fontWeight: "500", textAlign: "center", cursor: 'pointer' }} onClick={() => handleNavigationBackToAssetCategorization()}>Category Master</Button>
          }
          <ButtonPrimaryCTA 
            buttonText={isEdit ? "Save" : "Edit categorisation"}
            handleClick={() => {
              if (isEdit) {
                handleSave();
              } else {
                setIsEdit(true);
              }
            }}
            sxProps={{
              minWidth: "140px"
            }}
          />
        </Box>
      </Box>
      <AddCategoryModal
        isAddCategoryModalOpen={isAddCategoryModalOpen}
        setIsAddCategoryModalOpen={setIsAddCategoryModalOpen}
        handleAddCategory={handleAddCategory}
        headerText={modalConfig?.type === 'category' ? "Add new category" : "Add new sub-category"}
        subHeaderText={modalConfig?.type === 'category' ? 'Category Name' : 'Sub-Category Name'}
        listData={modalConfig?.type === 'category' 
          ? categoryOptions 
          : (modalConfig?.parentCategoryId 
            ? getSubCategoryOptions(modalConfig.parentCategoryId) 
            : [])}
      />
      <ConfirmModal
        headerText={`Reset to Default?`}
        subHeaderText={
          activeTab === TABS.ASSETS
          ? `Are you sure you wish to reset all custom assignments for individual assets to defaults? This will not reset any asset class-level custom assignments.`
          :
          `Are you sure you wish to reset custom assignments for all asset classes to defaults? This will not reset any individual asset-level custom assignments.`
        }
        infoText='Please note that this action cannot be undone.'
        isModalOpen={isResetToDefaultModalOpen}
        setIsModalOpen={setIsResetToDefaultModalOpen}
        confirmButtonText="Reset"
        apiResponse={resetToDefaultAPIResponse}
        onConfirm={() => {
          setResetToDefaultAPIResponse(undefined);
          dispatch(SharedRedux.Dashboard.Actions.resetCustomAssetAllocationToDefault({
            includeAssets: activeTab === TABS.ASSETS,
            includeAssetAllocationCategs: activeTab === TABS.PRODUCTS,
            onAPIResponse: (apiResponse: SharedConstants.FormSubmissionResponse) => {
              setResetToDefaultAPIResponse(apiResponse);
              if (apiResponse.IsSuccess) {
                setIsEdit(false);
                setSelectedMappings({});
              }
            }
          }));
        }}
        onCancel={onCancelResetToDefaultClick}
    />
    </>
  )
}

